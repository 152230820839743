import React, { useEffect, useMemo, useState } from "react";
import { format } from 'date-fns';
import {NotificationContainer, NotificationManager} from 'react-notifications';

// Chakra imports
import {
  Box,
  SimpleGrid,
  Flex,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Checkbox,
  useColorModeValue,
} from "@chakra-ui/react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Image, List, ListItem } from '@chakra-ui/react';

import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import axios from "axios";

export default function Settings() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [placesData, setPlacesData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [contentType, setContentType] = useState('');

  const apiUrl = process.env.REACT_APP_OPERATIONS_API_URL; // Use the operations API URL from your .env

  const showDetails = (title, content, type) => {
    setModalTitle(title);
    // Use the `type` parameter directly to determine how to handle `content`
    if (type === 'prices') {
      // Transform `price` object to an array format for display
      const pricesArray = transformPriceObjectToArray(content);
      setModalContent(pricesArray);
    } else if (type === 'specifications') {
      // Assuming specifications might already be in an appropriate array format
      // Ensure only specifications with quantity > 0 are set
      const filteredSpecs = Array.isArray(content) ? content.filter(spec => spec.quantity > 0) : [];
      setModalContent(filteredSpecs);
    } else {
      // Handle 'images' or other content types without needing to filter
      setModalContent(content);
    }
    setContentType(type); // Now safely update `contentType` for conditional rendering logic elsewhere
    onOpen();
  };


  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  useEffect(() => {
    loadPlaces();
  }, []);

  const loadPlaces = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.post(`${apiUrl}/admin/places/adminAllowed/true`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      // Assuming the API returns the users in the response body directly
      if (response.status === 200) {
        console.log(response.data)
        setPlacesData(response.data);
      } else {
        // Handle other success codes or unexpected responses
        console.log("Received an unexpected response:", response);
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Check if error.response exists and if the status is 401 or 403
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        NotificationManager.error('لقد انتهت الجلسة من فضلك قم بتسجيل الدخول مره اخري', 'موافق', 5000, () => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userEmail');
          localStorage.removeItem('avatar');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          localStorage.removeItem('userRole');
          
          window.location = '/auth';

        });
        // Redirect to login page or perform token refresh here, for example:
        // window.location = '/login';
      } else {
        // Handle other errors
        console.error("An error occurred while fetching users:", error);
        setErrorMessage("Failed to fetch users. Please try again later.");
      }
    }
  };

  const updateAvailability = async (placeId, isAvailable) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      // Assuming you have an endpoint to update the place's availability
      const response = await axios.post(`${apiUrl}/admin/places/update-availability`, {
        placeId,
        availability: isAvailable,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (response.status === 200) {
        // Assuming the API returns the updated place object or a success message
        // Update local state to reflect the change
        setPlacesData(currentPlaces =>
          currentPlaces.map(place =>
            place._id === placeId ? { ...place, availability: isAvailable } : place
          )
        );
        NotificationManager.success('تم تحديث حالة عرض العقار بنجاح', 'موافق', 5000);
      } else {
        console.log("Received an unexpected response:", response);
        setErrorMessage("An unexpected error occurred while updating availability.");
      }
    } catch (error) {
      console.error("An error occurred while updating availability:", error);
      setErrorMessage("Failed to update availability. Please try again later.");
    }
  };
  

  const formatSpecification = (spec) => {
    // You can add more ids and their corresponding Arabic descriptions here
    const descriptionsInArabic = {
      bedroom: 'غرف نوم',
      bathroom: 'حمامات',
      pool_internal: 'مسبح داخلي',
      pool_external: 'مسبح خارجي',
      carParking: 'مواقف سيارات',
      privateEntrance: 'مدخل خاص',
      tv: 'تلفاز',
      ac: 'تكييف',
      fireplace: 'مدفأة',
      elevator: 'مصعد',
      sharedEntrance: 'مدخل مشترك',
      bed: 'سرير',
      kitchen: 'مطبخ',
      wifi: 'واي فاي',
      towels: 'مناشف',
      hairDryer: 'مجفف شعر',
      bbq: 'شواية',
      livingRoom: 'غرفة معيشة',
      sportsArea: 'منطقة رياضية'
      
    };

    return `${descriptionsInArabic[spec.id] || spec.id}: ${spec.quantity}`;
  };

  const transformPriceObjectToArray = (priceObject) => {
    const keyDescriptions = {
      weekend: 'ويكند',
      weekdays: 'أيام الأسبوع',
      fullweek: 'الأسبوع كامل',
    };
  
    return Object.entries(priceObject).map(([key, value]) => {
      // Directly use the numeric value
      const priceValue = value; // Since value is already a number
      return `${keyDescriptions[key] || key}: ${priceValue} KWD`;
    });
  };

  const DetailsModal = ({ isOpen, onClose, title, content, contentType }) => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <br />
            <h2><b>{title}</b></h2>
            <br />
            {contentType === 'images' ? (
              content.map((imgUrl, index) => (
                <Image key={index} src={imgUrl} my={2} maxWidth="100%" borderRadius="md" />
              ))
            ) : contentType === 'prices' ? (
              <List spacing={3}>
                {content.map((priceDescription, index) => (
                  <ListItem key={index}>
                    <Text fontWeight="bold">{priceDescription}</Text>
                  </ListItem>
                ))}
              </List>
            ) : (
              <List spacing={3}>
                {content.map((spec, index) => (
                  <ListItem key={index}>
                    <Text fontWeight="bold">{formatSpecification(spec)}</Text>
                  </ListItem>
                ))}
              </List>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>إغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }


  const filteredData = useMemo(() => {
    const result = placesData.filter(
      (place) =>
        place.name.toLowerCase().includes(search.toLowerCase()) ||
        place.city.toLowerCase().includes(search.toLowerCase())
    );
    console.log("Filtered Data:", result);
    return result;
  }, [search, placesData]);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <Box pt={{ base: "100px", md: "10px", xl: "10px" }}>
      <SimpleGrid mb="20px" columns={1}   spacing='0px' w='full'>
        <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            
                  <Input
          placeholder="ابحث عن عقار"
          mb="15px"
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1); // Reset to the first page when search changes
          }}
  />
          </Flex>
          <Table className="tablee" variant="simple">
      <Thead>
        <Tr>
          <Th>الأسم</Th>
          <Th>الباقات</Th>
          <Th>المواصفات</Th>
          <Th>الصور</Th>
          <Th>رابط الخريطة</Th>
          <Th>المدينة</Th>
          <Th>عقار مسموح/موقوف</Th>
          <Th>تاريخ الاضافه</Th>
        </Tr>
      </Thead>
      <Tbody>
        {currentItems.map((place) => (
          <Tr key={place._id}>
            <Td>{place.name}</Td>
            <Td>
              <Button onClick={() => showDetails("الاسعار", place.price, 'prices')}>الاسعار</Button>
            </Td>
            <Td>
              <Button onClick={() => showDetails("المواصفات", place.specifications, 'specs')}>المواصفات</Button>
            </Td>
            <Td>
              <Button onClick={() => showDetails("معرض الصور", place.images, 'images')}>صور العقار</Button>
            </Td>
            <Td>
              <a href={place.mapsLink} target="_blank" rel="noopener noreferrer"><b>المكان علي الخريطة</b></a>
            </Td>
            <Td>{place.city}</Td>
            <Td>
              <Select
                defaultValue={place.adminAllowed ? 'مسموح' : 'موقوف'}
                onChange={(e) => updateAvailability(place._id, e.target.value === 'مسموح')}
              >
                <option value="مسموح">مسموح</option>
                <option value="موقوف">موقوف</option>
              </Select>
            </Td>
            <Td>
              {format(new Date(place.createdAt), 'yyyy/MM/dd')}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
          <DetailsModal
            isOpen={isOpen}
            onClose={onClose}
            title={modalTitle}
            content={modalContent}
            contentType={contentType}
          />
          <Flex justifyContent="center" mt="20px">
  {pageNumbers.map(number => (
    <Box
      key={number}
      cursor="pointer"
      p="2"
      color={currentPage === number ? "blue.600" : "gray.600"}
      onClick={() => paginate(number)}
    >
      {number}
    </Box>
  ))}
</Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
