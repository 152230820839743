import React, { useEffect, useMemo, useState } from "react";
import { format } from 'date-fns';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {Modal, ModalOverlay, ModalContent, ModalBody, Image, useDisclosure,  Select} from '@chakra-ui/react';

// Chakra imports
import {
  Box,
  SimpleGrid,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Checkbox,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import axios from "axios";

export default function Settings() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [usersData, setUsersData] = useState([]);
  const [originalUsersData, setOriginalUsersData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState('');
  const [filterVerified, setFilterVerified] = useState(false);


  const apiUrl = process.env.REACT_APP_OPERATIONS_API_URL; // Use the operations API URL from your .env

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const updateVerifiedStatus = async (userId, status) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      // Assuming you have an endpoint to update the place's availability
      const response = await axios.post(`${apiUrl}/admin/users/update-verified-status`, {
        userId,
        status: status,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (response.status === 200) {
        // Assuming the API returns the updated place object or a success message
        // Update local state to reflect the change
        setUsersData(currentUsers =>
          currentUsers.map(user =>
            user._id === userId ? { ...user, verified: status } : user
          )
        );
        NotificationManager.success('تم تحديث اعدادات المستخدم بنجاح', 'موافق', 5000);
      } else {
        console.log("Received an unexpected response:", response);
        setErrorMessage("An unexpected error occurred while updating availability.");
      }
    } catch (error) {
      console.error("An error occurred while updating availability:", error);
      setErrorMessage("Failed to update availability. Please try again later.");
    }
  };

  const disableAccount = async (userId, status) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      // Assuming you have an endpoint to update the place's availability
      const response = await axios.post(`${apiUrl}/admin/users/change-account-status`, {
        userId,
        status: status,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (response.status === 200) {
        // Assuming the API returns the updated place object or a success message
        // Update local state to reflect the change
        setUsersData(currentUsers =>
          currentUsers.map(user =>
            user._id === userId ? { ...user, enabled: status } : user
          )
        );
        NotificationManager.success('تم إيقاف حساب المستخدم بنجاح', 'موافق', 5000);
      } else {
        console.log("Received an unexpected response:", response);
        setErrorMessage("An unexpected error occurred while updating availability.");
      }
    } catch (error) {
      console.error("An error occurred while updating availability:", error);
      setErrorMessage("Failed to update availability. Please try again later.");
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    onOpen();
  };
  

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.post(`${apiUrl}/admin/users/disabled`, {}, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      // Assuming the API returns the users in the response body directly
      if (response.status === 200) {
        setUsersData(response.data);
        setOriginalUsersData(response.data);
      } else {
        // Handle other success codes or unexpected responses
        console.log("Received an unexpected response:", response);
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      // Check if error.response exists and if the status is 401 or 403
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        NotificationManager.error('لقد انتهت الجلسة من فضلك قم بتسجيل الدخول مره اخري', 'موافق', 5000, () => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userEmail');
          localStorage.removeItem('avatar');
          localStorage.removeItem('userId');
          localStorage.removeItem('userName');
          localStorage.removeItem('userRole');
          
          window.location = '/auth';

        });
        // Redirect to login page or perform token refresh here, for example:
        // window.location = '/login';
      } else {
        // Handle other errors
        console.error("An error occurred while fetching users:", error);
        setErrorMessage("Failed to fetch users. Please try again later.");
      }
    }
  };


  const filteredData = useMemo(() => {
    return usersData.filter(
      (user) =>
        user.username.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.phone.toLowerCase().includes(search.toLowerCase()) || 
        user.civilId.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, usersData]);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const applyFilterVerified = (isChecked) => {
    setFilterVerified(isChecked);

    if (isChecked) {
      // Filter usersData where `verified` is true
      const filteredUsers = usersData.filter(user => user.verified === true);
      setUsersData(filteredUsers);
    } else {
      // Reset to original users data when filter is not applied
      setUsersData(originalUsersData);
    }
  };


  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Image src={selectedImage} alt="Selected" maxWidth="100%" maxHeight="100vh" />
          </ModalBody>
        </ModalContent>
      </Modal>
    <Box pt={{ base: "100px", md: "10px", xl: "10px" }}>
      <SimpleGrid mb="20px" columns={1}   spacing='0px' w='full'>
        <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            
          <Flex direction="column" mb="15px">
  <Input
    placeholder="ابحث عن مستخدم"
    mb="4" // Adjusted for visual spacing
    onChange={(e) => {
      setSearch(e.target.value);
      setCurrentPage(1); // Reset to the first page when search changes
    }}
  />
  <Checkbox
    colorScheme="blue"
    onChange={(e) => applyFilterVerified(e.target.checked)}
  >
    الحسابات الموثقة
  </Checkbox>
 
</Flex>
          </Flex>
          <Box overflowX="auto"> {/* Ensure this Box wraps your Table for horizontal scrolling */}
          <Table className="tablee" color={textColor} borderColor={borderColor} style={{ tableLayout: 'auto' }}>
            <Thead>
              <Tr>
              <Th> {/* Empty header cell for the checkbox column */}</Th>
                <Th color={textColor}>الأسم</Th>
                <Th color={textColor}>البريد الإلكتروني</Th>
                <Th color={textColor}>الهاتف</Th>
                <Th color={textColor}>رقم الهوية</Th>
                <Th color={textColor}>الصورة</Th>
                <Th color={textColor}>صورة الهوية</Th>
                <Th color={textColor}>تاريخ الإنشاء</Th>
                <Th color={textColor}>حالة الحساب</Th>
                <Th color={textColor}>نوع الحساب</Th>
                <Th color={textColor}>مالك / مستأجر</Th>
                <Th color={textColor}>إيقاف/حذف الحساب</Th>
              </Tr>
            </Thead>
            <Tbody>                    
          {currentItems.map((user) => (
            <Tr key={user.id}>
              <Td>
                            <Checkbox
                              colorScheme="blue"
                              isChecked={selectedUsers.includes(user.id)}
                              onChange={() => handleCheckboxChange(user.id)}
                          />
                        </Td>
              <Td>{user.username}</Td>
              <Td>{user.email}</Td>
              <Td>{user.phone}</Td>
              <Td>{user.civilId}</Td>
              <Td onClick={() => handleImageClick(user.photo)}>
                <img src={user.photo} alt="user" style={{ width: "20", height: "20", cursor: 'pointer' }} />
              </Td>
              <Td onClick={() => handleImageClick(user.civilIdImg)}>
                <img src={user.civilIdImg} alt="user" style={{ width: "20", height: "20", cursor: 'pointer' }} />
              </Td>
              <Td style={{ minWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {format(new Date(user.createdAt), 'MMMM dd, yyyy, hh:mm:ss a')}
              </Td>
              <Td style={{ minWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Select
                onChange={(e) => updateVerifiedStatus(user._id, e.target.value === 'موثق')}
              >
                <option value="موثق" selected={user.verified}>موثق</option>
                <option value="غير موثق" selected={!user.verified}>غير موثق</option>
              </Select>
            </Td>
            <Td style={{ minWidth: '170px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {user.activated ? 'مفعل' : ' غير مفعل'}
            </Td>
            <Td>{user.role == 'owner' ? 'مالك' : 'مستأجر'}</Td>
            <Td style={{ minWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Select
                onChange={(e) => disableAccount(user._id, e.target.value === 'enabled')}
              >
                <option value="enabled" selected={user.enabled}>حساب مُفعل</option>
                <option value="disabled" selected={!user.enabled}>إغلاق حساب</option>
              </Select>
            </Td>

            </Tr>
          ))}
        </Tbody>

          </Table>
          </Box>
          <Flex justifyContent="center" mt="20px">
  {pageNumbers.map(number => (
    <Box
      key={number}
      cursor="pointer"
      p="2"
      color={currentPage === number ? "blue.600" : "gray.600"}
      onClick={() => paginate(number)}
    >
      {number}
    </Box>
  ))}
</Flex>
        </Card>
      </SimpleGrid>
    </Box>
    </>
  );
}
