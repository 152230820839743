import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson, // For users
  MdHome, // For properties
  MdOutlineShoppingCart, // For reservations (assuming reservations involve booking or purchasing)
} from "react-icons/md";

// Admin Imports
import UsersList from "views/admin/UsersList";
import UsersListDisabled from "views/admin/UsersListDisabled";
import PlacesList from "views/admin/PlacesList";
import PlacesListDisabled from "views/admin/PlacesListDisabled";
import ReservationsList from "views/admin/ReservationsList";
import Home from "views/admin/Home";

const signOutComponent = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('avatar');
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('userRole');
  
  window.location = '/auth';
}

const routes = [
  // {
  //   name: "الصفحة الرئيسية",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: Home,
  //   showInSidebar: true, // Add this line

  // },
  {
    name: "الحسابات الفعالة",
    layout: "/admin",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    path: "/users/active",
    component: UsersList,
    showInSidebar: true, // Add this line
  },
  {
    name: "الحسابات الموقوفة",
    layout: "/admin",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    path: "/users/disabled",
    component: UsersListDisabled,
    showInSidebar: true, // Add this line
  },
  {
    name: "العقارات الحالية",
    layout: "/admin",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    path: "/places/active",
    component: PlacesList,
    showInSidebar: true, // Add this line
  },
  {
    name: "العقارات الموقوفة",
    layout: "/admin",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    path: "/places/disabled",
    component: PlacesListDisabled,
    showInSidebar: true, // Add this line
  },
  {
    name: "الحجوزات",
    layout: "/admin",
    icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
    path: "/reservations",
    component: ReservationsList,
    showInSidebar: true, // Add this line
  },
  
  
];

export default routes;
