// Chakra imports
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position='relative' h='max-content'>
  <Flex
    
    w='100%'
    mx='auto'
    pt={{ sm: "50px", md: "0px" }}
    px={{ lg: "30px", xl: "0px" }}
    ps={{ xl: "70px" }}
    justifyContent='center' // This ensures vertical centering for direction='column'
    alignItems='center' // This ensures horizontal centering for direction='column'
    direction='column'>
    {children}
    <Box
      display={{ base: "none", md: "block" }}
      h='100%'
      minH='100vh'
      position='absolute'
      right='0px'>
      <Flex
        bg={`url(${illustrationBackground})`}
        justify='center'
        align='end'
        w='100%'
        h='100%'
        bgSize='cover'
       
        position='absolute'
        ></Flex>
    </Box>
    <Footer />
  </Flex>
  <FixedPlugin />
</Flex>

  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
