import React, { useState } from 'react';
import axios from 'axios';
import EmailValidator from 'email-validator';
import { NavLink } from 'react-router-dom'; // Assuming you're using react-router-dom
import { useHistory } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignIn() {
  const history = useHistory();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const apiUrl = process.env.REACT_APP_OPERATIONS_API_URL; // Use the operations API URL from your .env
  
    const login = async () => {

      // Check if accessToken exists and redirect before attempting to login
      if (localStorage.getItem('accessToken')) {
        history.push('/admin');
        return;
      }

      setLoading(true);
      setErrorMessage('');

  
      if (!email || !EmailValidator.validate(email)) {
        setErrorMessage("برجاء ادخال بريد الكتروني صحيح");
        setLoading(false);
        return;
      }
  
      if (!password) {
        setErrorMessage("برجاء عدم ترك الحقول فارغة");
        setLoading(false);
        return;
      }
  
      try {
        const response = await axios.post(`${apiUrl}/admin/signin`, {
          email,
          password,
        });
  
        if (response.status === 200 && response.data.login) {
          // Assuming you have a way to globally store these or you might use localStorage/sessionStorage
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('userEmail', email);
          localStorage.setItem('avatar', response.data.avatar);
          localStorage.setItem('userId', response.data.userId);
          localStorage.setItem('userName', response.data.userName);
          localStorage.setItem('userRole', response.data.userRole);
          history.push('/admin');

          // Navigate to home or another page based on your routing setup
          // For example, using React Router: history.push('/');
        } else {
          setErrorMessage('البريد الالكتروني او كلمة المرور غير صحيحة');
        }
      } catch (error) {
        console.error('Login error:', error);
        setErrorMessage('البريد الالكتروني او كلمة المرور غير صحيحة');
      } finally {
        setLoading(false);
      }
    };

    const handlePasswordVisibility = () => setShow(!show);

  
  return (
    <DefaultAuth>
      <Flex
        _rtl={{ flexDirection: "row-reverse" }}
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
         
         <FormControl>
      {errorMessage && <Text color="red.500">{errorMessage}</Text>}
      <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
        Email<Text color={brandStars}>*</Text>
      </FormLabel>
      <Input
        isRequired={true}
        variant='auth'
        fontSize='sm'
        type='email'
        placeholder='mail@simmmple.com'
        mb='24px'
        fontWeight='500'
        size='lg'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
        Password<Text color={brandStars}>*</Text>
      </FormLabel>
      <InputGroup size='md'>
        <Input
          isRequired={true}
          fontSize='sm'
          placeholder='Min. 8 characters'
          mb='24px'
          size='lg'
          type={show ? "text" : "password"}
          variant='auth'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputRightElement display='flex' alignItems='center' mt='4px'>
          <Icon
            color={textColorSecondary}
            _hover={{ cursor: "pointer" }}
            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={handlePasswordVisibility}
          />
        </InputRightElement>
      </InputGroup>
      <Flex justifyContent='space-between' align='center' mb='24px'>
        {/* Your checkbox and forgot password link */}
      </Flex>
      <Button
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        w='100%'
        h='50'
        mb='24px'
        onClick={login}
        isLoading={loading}>
        Sign In
      </Button>
    </FormControl>
          
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
