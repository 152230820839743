import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import ProtectedRoute from 'components/AuthWrapper';
import SignInCentered from "views/auth/signIn";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

ReactDOM.render(
	<ChakraProvider theme={theme}>
	  <React.StrictMode>
		<ThemeEditorProvider>
		  <HashRouter>
			<Switch>
			  <Route path="/auth" component={SignInCentered} />
			  <ProtectedRoute path="/admin" component={AdminLayout} />
			  <Redirect from="/" to="/admin" />
			</Switch>
			<NotificationContainer />
		  </HashRouter>
		</ThemeEditorProvider>
	  </React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
  );
